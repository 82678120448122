import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.from('.hero__text', {
  duration: 2,
  opacity: 0,
  x: -150,
  delay: 1,
});

gsap.from('.hero__image', {
  duration: 2,
  opacity: 0,
  x: 150,
  delay: 1,
});

const elements = ['.vision__section', '.reveal__section', '.princibles__section', '.do__section', '.roles__section'];

elements.forEach((selector) => {
  gsap.from(selector, {
    opacity: 0,
    duration: 1.5,
    y: 150,
    ease: 'power3.out',
    scrollTrigger: {
      trigger: selector, // Trigger each element when it enters the viewport
      start: 'top 90%', // Start animation when top of element is 90% from the top of the viewport
      toggleActions: 'play none none none',
    },
  });
});
