document.addEventListener('DOMContentLoaded', () => {
  const slides = document.querySelectorAll('.slide');
  const totalSlides = slides.length; // Should now be 6
  let currentIndex = 0;

  const sliderContainer = document.querySelector('.slider-container');

  // Function to calculate number of visible slides based on screen size
  function getVisibleSlides() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) return 4; // Large Desktop: 4 slides visible
    if (screenWidth >= 1024) return 3; // Desktop: 3 slides visible
    if (screenWidth >= 768) return 2; // Tablet: 2 slides visible
    return 1; // Mobile: 1 slide visible
  }

  // Function to move the slider to a specific slide
  function goToSlide(index) {
    const visibleSlides = getVisibleSlides();
    const newX = -(index * (100 / visibleSlides)) + '%'; // Adjust based on visible slides
    sliderContainer.style.transform = `translateX(${newX})`;

    // Update pagination dots
    updateDots(index);
  }

  // Function to update pagination dots
  function updateDots(index) {
    const dots = document.querySelectorAll('.pagination-dots .dot');
    dots.forEach((dot, i) => {
      dot.classList.toggle('active', i === index);
    });
  }

  // Create pagination dots dynamically
  function createDots() {
    const paginationDots = document.querySelector('.pagination-dots');
    paginationDots.innerHTML = ''; // Clear existing dots if any

    for (let i = 0; i < totalSlides; i++) {
      const dot = document.createElement('div');
      dot.classList.add('dot');
      dot.addEventListener('click', () => {
        currentIndex = i;
        goToSlide(currentIndex);
      });
      paginationDots.appendChild(dot);
    }

    // Set the first dot as active
    updateDots(0);
  }

  // Event listener for 'Next' button
  document.querySelector('.next').addEventListener('click', () => {
    if (currentIndex < totalSlides - 1) {
      currentIndex++;
    } else {
      currentIndex = totalSlides - 1; // Prevent going beyond the last slide
    }
    goToSlide(currentIndex);
  });

  // Event listener for 'Prev' button
  document.querySelector('.prev').addEventListener('click', () => {
    if (currentIndex > 0) {
      currentIndex--;
    } else {
      currentIndex = 0; // Prevent going before the first slide
    }
    goToSlide(currentIndex);
  });

  // Swipe functionality for touch devices
  let startX = 0;
  let currentX = 0;
  let isDragging = false;

  sliderContainer.addEventListener('touchstart', (e) => {
    startX = e.touches[0].clientX;
    isDragging = true;
  });

  sliderContainer.addEventListener('touchmove', (e) => {
    if (!isDragging) return;
    currentX = e.touches[0].clientX;
  });

  sliderContainer.addEventListener('touchend', () => {
    if (!isDragging) return;
    const diffX = startX - currentX;

    // If swipe is significant (threshold), trigger next or previous slide
    if (diffX > 50 && currentIndex < totalSlides - 1) {
      currentIndex++; // Swipe left (next slide)
    } else if (diffX < -50 && currentIndex > 0) {
      currentIndex--; // Swipe right (previous slide)
    }

    goToSlide(currentIndex);
    isDragging = false;
  });

  // Initialize the slider with the first slide
  goToSlide(currentIndex);
  createDots();

  // Recalculate slides on window resize
  window.addEventListener('resize', () => {
    goToSlide(currentIndex); // Adjust based on the new visible slides
  });
});
